<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
            
              <b-col lg="6">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.mrfj_code" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.mrfj_code" 
                    :rules="toValidate(mrValidation.mrfj_code)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Nama<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="row.mrfj_name"/>
                  <VValidate 
                    name="Nama" 
                    v-model="row.mrfj_name" 
                    :rules="toValidate(mrValidation.mrfj_name)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- 
            <b-row>
              <b-col lg="12">
                <label for="">Daftar Dokter</label>
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr class="table-secondary">
                      <th>Nama</th>
                      <th width="100">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.mrfj_daftar_dokter" :key="k">
                      <td>
                      <input type="text" v-model="row['mrfj_daftar_dokter'][k]['name']" class="form-control" placeholder="e.g. Rara Gumelar">
                      <VValidate 
                        :name="'Nama #'+(k+1)" 
                        v-model="row['mrfj_daftar_dokter'][k]['name']" 
                        :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 512}"
                      />
                      </td>
                      <td class="text-center">
                        <i v-if="row.mrfj_daftar_dokter.length > 1" @click="removeRow(k)" class="icon-trash"></i>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4">
                        <div class="text-center">
                          <button type="button" @click="addRow()" class="btn bg-blue-400 btn-labeled btn-labeled-left"><b><i class="icon-plus-circle2"></i></b> Tambah Field</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </b-col>
            </b-row>
            -->
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    addRow(){
      let payload = {
        "name":""
      }
      this.row.mrfj_daftar_dokter.push(payload)
    },
    removeRow(k){
      this.row.mrfj_daftar_dokter.splice(k,1)      
    },
  },
}
</script>